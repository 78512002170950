<template>
  <div class="car">
    <div class="productList">
      <div class="productItem"
        v-for="(productItem,productIndex) in carList"
        :key="productIndex">
        <img :src="productItem.image ? productItem.image.thumbnailStaticUrl : require('@/assets/productDefault.png')">
        <div class="right">
          <div class="titleBox">
            {{productItem.title1 || '未命名'}}
          </div>
          <div class="guid">
            {{productItem.memo}}
          </div>
          <div class="optionBox">
            <div>￥ <span>{{productItem.carPrice}}</span></div>
            <van-icon name="delete"
              @click="delcar(productIndex)" />
          </div>
        </div>
      </div>
    </div>

    <div class="carBottom">
      <div class="roundPost">
        <div class="bottomPrice">
          <div>￥<span class="number">{{totalPrice}}</span></div>
          <div class="memo">下单后，厨房开始备菜</div>
        </div>
        <div class="postBtn"
          @click="postOrder">立即下单</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'car',
  data() {
    return {
      // 购物车
      carList: JSON.parse(localStorage.getItem('car')) || []
    }
  },
  computed: {
    totalPrice() {
      let number = 0
      for (const item of this.carList) {
        number += Number(item.carPrice || 0)
      }
      return number
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 删除
    delcar(index) {
      this.carList.splice(index, 1)
      localStorage.setItem('car', JSON.stringify(this.carList))
    },
    // 提交订单
    postOrder() {
      let detail = this.carList.map(item => {
        return {
          productId: item.id,
          memo: item.memo,
          boxNumber: item.buyGuid == 'boxPrice' && 1,
          bigBagNumber: item.buyGuid == 'bigBagPrice' && 1,
          bagNumber: item.buyGuid == 'bagPrice' && 1,
          unitNumber: item.buyGuid == 'unitPrice' && 1
        }
      })
      this.$http
        .post(
          `https://${localStorage.getItem(
            'url'
          )}/be/api/restaurant/guest/desk/details`,
          {
            deskId: localStorage.getItem('id'),
            details: detail
          }
        )
        .then(res => {
          this.saveOrder(localStorage.getItem('id'))
        })
    },
    // 保存订单
    saveOrder() {
      this.$http
        .post(
          `https://${localStorage.getItem(
            'url'
          )}/be/api/restaurant/guest/desk/save`,
          {
            deskId: localStorage.getItem('id')
          }
        )
        .then(res => {
          localStorage.setItem('car', '[]')
          this.carList = []
        })
    }
  }
}
// 商业险
// 贷款服务费
// 利息
// 上牌费
// 出库费
// 抵押费
// pdi检测费
</script>

<style scoped lang="scss">
.car {
  padding-bottom: 7rem;
  height: calc(100% - 6rem);
  position: relative;
  box-sizing: border-box;
}

.productList {
  overflow: auto;
  height: 100%;
  .productItem {
    height: 12rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid rgb(236, 236, 236);
    display: flex;
    align-items: center;
    img {
      background: #f5f5f5;
      margin-right: 1rem;
      width: 10rem;
      height: 10rem;
      object-fit: contain;
    }
    .right {
      height: 100%;
      flex: 1;
      .titleBox {
        text-align: left;
        font-weight: 600;
        height: 3rem;
        line-height: 1.5rem;
        font-size: 1.6rem;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .guid {
        line-height: 2rem;
        font-size: 1.6rem;
        color: #999;
        text-align: left;
        margin-top: 1rem;
      }
      .optionBox {
        height: 3rem;
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        div {
          font-size: 1.4rem;
          color: #333;
          span {
            font-size: 2rem;
            color: #ee0a24;
          }
        }
        .van-icon {
          color: #ee0a24;
          font-size: 3rem;
        }
      }
    }
  }
}

.carBottom {
  // background: white;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 7rem;
  padding: 1rem;
  box-sizing: border-box;
  .roundPost {
    width: 100%;
    border-radius: 30px;
    background: #333;
    height: 100%;
    display: flex;
    align-items: center;
    .bottomPrice {
      flex: 1;
      text-align: left;
      padding-left: 2rem;
      color: #f9f9f9;
      .number {
        color: #ee0a24;
        font-size: 1.8rem;
      }
    }
    .postBtn {
      float: right;
      height: 100%;
      width: 14rem;
      border-radius: 30px;
      background: #1989fa;
      color: white;
      font-size: 1.8rem;
      line-height: 5rem;
    }
  }
}
</style>
